.addthis_sharing_toolbox {
    display: inline-block;
}

li a.nopaddings {
	margin: 0;
	padding: 0;
}

.break-word {
    word-wrap: break-word;
}

.shop-item-detail-price ins.amount {
  color: #3dace1;
}

.strike {
    text-decoration:        line-through;
}

/*
    Events
 */
#events {
	
    margin-bottom: 10px;
    padding-top: 10px;
    //.event-shadow {
   	//	-webkit-box-shadow: 0 0 20px #ea0808;
	//}

	.shop-label {
	    top:35px;
	    white-space: nowrap;
	    overflow: hidden; 
	    text-overflow: ellipsis;
	    max-width: 150px;
	}

	.shop-label:hover {
	    top:35px;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    max-width: none;
	    z-index: 10;
	}

    .shop-label.bg-red:hover {
      top: 1px;
      padding: 20px;
      left: 11px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: none;
      z-index: 10;
      width: 164px;
      height: 114px;
    }

	.user-name {
	    text-align: center;
	    color:white;
	    padding: 3px;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	}

	.shop-item-container-in {
        height:115px;
	    min-height: 115px;
	    margin-top:0;
	    overflow: hidden;
	}
}

.p0 {
    padding: 0;
}

#user-balance {
    display: inline-block;
}

.user-avatar {
	height: 50px;
}

.market-item-header {
    font-size: 10px;
    position: top;
}

.market-item {
  height: 85px;
  margin-left: auto;
}

.market-item-description {
    font-size: 10px;
    position: bottom;
    overflow: hidden;
    height: 15px;
}

.market-pagination {
    margin-top: 2%;
}

#pricefrom, #priceto {
    width: 50%;
    margin-bottom: 10px;
}

#js-next-page {
    cursor: pointer;
}

#js-gotopage {
    padding: 0px 0px 0px 15px;
    width: 55px;
    height: 31px;
}

.button-5mt {
    margin-top: 5px;
}

.loader {
	display:none;
}

.have-link {
	display:none;
}

.howitworks {
    position: fixed; 
    right:-78px; 
    top:50%;
    z-index: 10;
}

.reset-search {
    border-bottom: 1px solid #f1f5f9;
    padding-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
}

#process-loader {
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.process-loader-item {
    /*margin: auto;*/
    position: absolute;
    top: -80px;
    left: 80px;
    bottom: 0;
    right: 0;
    height: 40px;
    width: 40px;
    z-index: 1;
}

.rotate-text {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);

  /* also accepts left, right, top, bottom coordinates; not required, but a good idea for styling */
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;

  /* Should be unset in IE9+ I think. */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.cssload-container {
    position: relative;
    width: 97px;
    height: 224px;
    overflow: hidden;
    margin:0px auto;
}

.cssload-container .cssload-item {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 49px;
    height: 49px;
    background-color: rgb(255,69,48);
    box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
        -o-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
        -ms-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
        -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
        -moz-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
}

.cssload-container .cssload-moon {
    border-bottom: 10px solid rgb(255,255,255);
    border-radius: 50%;
        -o-border-radius: 50%;
        -ms-border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    animation: spin 1.15s ease infinite;
        -o-animation: spin 1.15s ease infinite;
        -ms-animation: spin 1.15s ease infinite;
        -webkit-animation: spin 1.15s ease infinite;
        -moz-animation: spin 1.15s ease infinite;
}

.width400 {
    width: 400px;
}

.zindex1 {
    z-index: 1;
}

.zindex100 {
    z-index: 100;
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}



@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes spin {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}